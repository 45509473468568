import { useQuery } from '@tanstack/react-query';
import { useWebConfig } from 'features/configuration/queries';
import { kiyOhKeys } from '.';
import { fetchKiyOhReviews } from './connector';

const useKiyohReviews = () => {
  const { data: webConfig } = useWebConfig();

  const enableKiyoh = webConfig?.enableKiyoh;

  return useQuery({
    enabled: enableKiyoh,
    queryFn: () => fetchKiyOhReviews(),
    queryKey: kiyOhKeys.kiyOhReviews(),
  });
};

export { useKiyohReviews };
