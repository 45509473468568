import { apiClient } from 'helpers/ApiClient';
import { logErrorResponse } from 'utils/loggerUtil';

const fetchKiyOhReviews = async () => {
  try {
    const { data } = await apiClient({
      url: `/kiyoh`,
    });

    return data;
  } catch (error) {
    logErrorResponse('KiyOh Connector', error, `Error fetching kiyOh reviews`);
    throw error;
  }
};

export { fetchKiyOhReviews };
